import React, { useState, useEffect } from 'react';
import './App.scss';
import HeaderGlobal from './components/Header/Header';
import { Route, Switch } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import EndpointsPage from './pages/EndpointsPage';
import PilotosPage from './pages/PilotosPage';
import CopilotosPage from './pages/CopilotosPage';
import CalendarioPage from './pages/CalendarioPage';
import LoginPage from './pages/LoginPage';
import PerfilPage from './pages/PerfilPage';
import ChangePswdPage from './pages/ChangePswdPage';
import AyudaPage from './pages/AyudaPage';
import { getLocalLoginInfo, loginRefresh, removeLocalLoginInfo, setLocalLoginInfo } from './reducers/loginReducer';
import { Redirect } from "react-router-dom";
import axios from 'axios'

axios.interceptors.response.use(undefined, function axiosLogoutInterceptor(err) {
  if (err.response.status === 403) {
    if (getLocalLoginInfo().access !== "") {
      removeLocalLoginInfo()
      window.alert("Tu sesión ha expirado!")
      window.location.replace("/")
    }
  }

  return Promise.reject(err)
})

function App() {
  const TOKEN_REFRESH_TIME = (10 * 1000) * 60;

  const [loginInfo, setLoginInfo] = useState(getLocalLoginInfo())
  const [userLogged, setUserLogged] = useState(loginInfo.access !== '')

  useEffect(() => {
    setLoginInfo(getLocalLoginInfo())
    setUserLogged(loginInfo.access !== '')

    if (userLogged === true) {
      const refresh_interval = setInterval(
        () => {
          loginRefresh().then((data) => {
            let info = getLocalLoginInfo()
            info.access = data.access
            setLocalLoginInfo(info)
          }).catch((err) => { })
        },
        TOKEN_REFRESH_TIME
      );

      return () => {
        clearInterval(refresh_interval)
      }
    }

  }, [loginInfo.access, userLogged, TOKEN_REFRESH_TIME])

  return (
    <div className="App">
      {userLogged ? (
        <>
          <header className="App-header">
            <HeaderGlobal />
          </header>
          <div>
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route path="/endpoints" component={EndpointsPage} />
              <Route path="/pilotos" component={PilotosPage} />
              <Route path="/copilotos" component={CopilotosPage} />
              <Route path="/calendario" component={CalendarioPage} />
              <Route path="/perfil" component={PerfilPage} />
              <Route path="/ayuda" component={AyudaPage} />
            </Switch>
          </div>
        </>
      ) : (
        <div>
            <Route exact path="/" component={LandingPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/change_pswd" component={ChangePswdPage} />
            <Redirect to="/login" />
        </div>
      )}
    </div>
  );
}

export default App;
