import { getAuthenticationHeader } from '../reducers/loginReducer';
import axios from 'axios';

const api_url = process.env.REACT_APP_BACKEND_URL;
const base_url = `${api_url}/api/`;

export const makeLogin = async ({username, password}) => {
    const {data} = await axios.post(`${base_url}login/`, {username, password})
    return data
}

export const refreshLogin = async (refresh) => {
    const config = getAuthenticationHeader()
    const {data} = await axios.post(`${base_url}login/refresh/`, {"refresh": refresh}, config)
    return data
}

export const logout = async () => {
    const config = getAuthenticationHeader()
    const {data} = await axios.post(`${base_url}logout/`, {}, config)
    return data
}