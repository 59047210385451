import {changePswd, getAll} from '../services/users';
import { getLocalLoginInfo } from './loginReducer';

export const usersReducer = (state = [], action) => {
    if(action.type === '@users/init'){
        
        return action.payload
    }

    return state
}

export const pswdChange = (new_password1, new_password2) => { 
    const loginInfo = getLocalLoginInfo()
    const credentials = {
        "userid":loginInfo.userid,
        "new_password1":new_password1,
        "new_password2":new_password2,
    }
    return changePswd(credentials)
}

export const initUsers = (bool, page) => {
    return async (dispatch) => {
        const users = await getAll(bool)
        dispatch ({
            type: '@users/init',
            payload: users,
        })
    }
}