import React from 'react';
import './coupleCard.scss';
import {
    Bicycle32,
    Group32
} from '@carbon/icons-react';

import {
    Tile,
} from 'carbon-components-react'

// Content
function Couple({ couple, filter }) {

    if (couple.bicicleta === parseInt(filter) || parseInt(filter) === 0) {
        return (<div key={couple.id} className="bx--col">
            <Tile className="tarjeta">
                <div>
                    <Group32 color="#0082ce" />
                    <p ><u>Piloto:</u> {couple.piloto}</p>
                    <p ><u>Copiloto:</u> {couple.copiloto}</p>
                    <Bicycle32 color="#0082ce" /><p>{couple.nombre_bicicleta}</p>
                    <br />
                </div>
            </Tile>
        </div>)
    } else {
        return <></>
    }
}

// Container
// Tarjeta que muestra la pareja que va a asistir a un entreno
const CoupleCard = (props) => {
    return (
        <Tile>
            <div className="bx--col">
                {props.couples.map(couple =>
                    <Couple key={couple.id} couple={couple} filter={props.filter.filter} />)}
            </div>
        </Tile>
    );
};
export default CoupleCard;