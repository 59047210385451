import image from '../../../imgs/perfil.png';
import React from 'react';
import './Users.scss';
import {
    Tile,

} from 'carbon-components-react';

import {
    Bicycle32
} from '@carbon/icons-react';

// Content
function User({ user }) {
    let img = ''
    user.imagen === null ? img = image : img = user.imagen
    return <div key={user.id} className="bx--col">
        <Tile className="user">
            <div>
                <img src={img} alt="imagen del usuario" height="200" width="200" />
                <h5 className="user-name">{user.nombre} {user.apellidos}</h5>
                {/* Lista bicis */}
                <Bicycle32 color="#0082ce" />
                <div className="user-bikes">
                    {user.bicicletas.map(bike => <p key={'b' + String(bike.abreviatura)}> {bike.nombre} </p>)}
                </div>
            </div>
        </Tile>

    </div>
}

// Container
export default function Users(users) { 

    //devuelve todas las notas
    return (
        <>
            <div className="bx--row">
                {users.users.map(user =>
                    <User key={user.id} user={user} />)}
            </div>
        </>
    );
}
