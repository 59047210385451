import React, {useState, useEffect} from 'react';
import './Perfil.scss';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button
} from 'carbon-components-react';

import { getLocalLoginInfo } from '../../reducers/loginReducer';
import { initTrains } from '../../reducers/trainsReducer';
import { getUser } from '../../services/users';
import TrainDay from './TrainDay';
import  FamilyFriendListModal  from '../Perfil/FamilyFriendListModal/FamilyFriendListModal'
import  BikeListModal  from '../Perfil/BikeListModal/BikeListModal'


function Perfil() {
  const trains = useSelector(state => state.trains);
  const dispatch = useDispatch()

  const [user, setUser] = useState(undefined)
  const [isAdmin, setIsAdmin] = useState(false)
  const [displayModalFamily, setDisplayModalFamily] = useState(false)
  const [displayModalBikes, setDisplayModalBikes] = useState(false)

  const openFamilyListModal = () => {
    setDisplayModalFamily(true);
  }

  const closeFamilyListModal = () => {
    setDisplayModalFamily(false);
  }

  const openBikesListModal = () => {
    setDisplayModalBikes(true);
  }

  const closeBikesListModal = () => {
    setDisplayModalBikes(false);
  }

  useEffect(() => {
    dispatch(initTrains('all'))
    getUser(getLocalLoginInfo().userid).then((data) => {
      setUser(data)
    })
    
    setIsAdmin(getLocalLoginInfo().admin)
  }, [dispatch])

  const handleTurnUpdate = () => {
    dispatch(initTrains('all'))
    getUser(getLocalLoginInfo().userid).then((data) => {
      setUser(data)
    });
  }

  const createTurns = (trains) => {
    if (user === undefined) return <h4>Cargando...</h4>
    const turns = Object.keys(trains).map(function (key) {
      return <TrainDay key={key} date={key} trainDay={trains[key]} user={user} updateData={handleTurnUpdate}/>
    });

    return turns;
  }

  return (
    <div>
      {user !== undefined && 
        <div className="user-profile">
          <div>
            <h1>{user.nombre} {user.apellidos}</h1>
            {(user.piloto === true && user.copiloto === false) ?
              <h2>Piloto</h2> :
              <h2>Copiloto</h2>
            }
            <Button className="perfil-button-bikes" onClick={openBikesListModal}>Lista de bicicletas</Button>
            <BikeListModal open={displayModalBikes} user={user} onClose={closeBikesListModal}/>
            {isAdmin && 
              <>  
                <Button className="perfil-button-friends" onClick={openFamilyListModal}>Lista de amigos</Button>
                <FamilyFriendListModal open={displayModalFamily} user={user} onClose={closeFamilyListModal} onChange={handleTurnUpdate}/>
              </>
            }
            <br/>
          </div>
        </div>
      }
      {createTurns(trains)}
    </div>
  );
};
export default Perfil;


