import React, {useState, useEffect} from 'react';
import styles from './EndpointsList.module.scss';
import { fetchEndpoints } from './EndpointsListAPI';

export function EndpointsList() {
  const [endpoints, setEndpoints] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchEndpoints()
      .then(response => response.json())
      .then(data => {
        data = Object.keys(data).map(x => {
          return <li key={x}><b>{x}</b>: {data[x]}</li>
        })
        setEndpoints(data);
        setLoading(false);
      })
      .catch(console.err)
  })

  if (isLoading) {
    return <div><ul><li>Empty list</li></ul></div>
  } 
  return (
    <div>
      <ul className={styles.blue_color}>
        {endpoints}
      </ul>
    </div>
  );
}
