import axios from 'axios';
import { getAuthenticationHeader } from '../reducers/loginReducer';

const api_url = process.env.REACT_APP_BACKEND_URL;
const base_url = `${api_url}/api/bicicletas`;

export const getAll = async () => {
    const config = getAuthenticationHeader()
    const res = await axios.get(`${base_url}/`, {headers: config.headers})
    return res.data.results //bikes
}

export const updateBicicleta = async (bike) => {
    const config = getAuthenticationHeader()
    const response = await axios.put(`${base_url}/${bike.id}/`, bike, config)
    return response.data
}