import { useCallback, useEffect, useState } from 'react';
import '../../pages/CalendarioPage/_calendario-page.scss';
import { useDispatch } from 'react-redux';
import { initTrains, isEmpty } from '../../reducers/trainsReducer';
import { getLocalLoginInfo } from '../../reducers/loginReducer';

import BikesSelector from '../../components/Calendario/BikesSelector/BikesSelector';

import {
  PreviousOutline32,
  NextOutline32
} from '@carbon/icons-react';

const CalendarioPage = () => {

  // Saber si es admin o no para mostrarle un entreno u otro
  let admin = getLocalLoginInfo().admin

  const [calendarPage, changeCalendarPage] = useState(0);
  const dispatch = useDispatch()
  const nextPage = calendarPage + 1;
  const previousPage = calendarPage - 1;

  const [empty, setEmpty] = useState(true)

  const checkisEmpty = useCallback(async () => {
    const _empty = await isEmpty(nextPage)
    setEmpty(_empty)
  }, [nextPage])

  function nextDays() {
    if (admin || !empty) {
      changeCalendarPage(nextPage)
    }
  }

  function prevDays() {
    if (calendarPage !== 0) {
      changeCalendarPage(previousPage)
    }
  }

  useEffect(() => {
    dispatch(initTrains(calendarPage))
    if (!admin) {
      checkisEmpty(nextPage)
    }  
  }, [dispatch, checkisEmpty, admin, calendarPage, nextPage])  

  return (
    <>
      <div className="button-days">
        <PreviousOutline32 className={calendarPage === 0 ? ("button-disabled") : ("transition-left")} onClick={() => prevDays()} />
        <NextOutline32 className={(admin) ? ("transition-right") : (empty ? ("button-disabled") : ("transition-right"))} color="#0082ce" onClick={() => nextDays()} />
      </div>
      <BikesSelector calendarPage={calendarPage} admin={admin} />
    </>
  );
};

export default CalendarioPage;

