import React from 'react';
import './BikeListModal.scss'

import {
    Modal,
} from 'carbon-components-react';
import {
    Bicycle32,
  } from '@carbon/icons-react';

const BikeListModal = ({ open, user, onClose }) => {

    const getUserBikes = () => {
        let bikes = []
        const bikes_no = Object.keys(user.bicicletas).length
        for (let index = 0; index < bikes_no; index++) {
          bikes.push(<h4 key={String(user.bicicletas[index].nombre)}> <Bicycle32 key={'b' + String(user.bicicletas[index].nombre)} color="#0082ce"/> {user.bicicletas[index].nombre} </h4>)
        }

        return bikes
      }

    return (
        <Modal
            open = {open}
            modalLabel = "Lista de bicicletas"
            modalHeading="Bicicletas disponibles para usar:"
            primaryButtonText="Close"
            passiveModal={true}
            onRequestClose={onClose}
            className="train-modal">
            {getUserBikes()}
        </Modal>
    );
}

export default BikeListModal;