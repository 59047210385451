import React, { useState, useEffect, useCallback } from 'react';
import './FamilyFriendListModal.scss';

import { addFriend, deleteFriend, getAllUsers, updateFriendship } from '../../../services/users';

import {
    Modal,
    DataTable,
    TableContainer,
    Table,
    TableHead,
    TableHeader,
    TableBody,
    TableRow,
    TableCell,
    Select,
    SelectItem,
    Button,
    Checkbox,
    TextInput,
} from 'carbon-components-react';

import {
    TrashCan32,
    DocumentAdd32,
    ArrowDown32,
    ArrowUp32,
  } from '@carbon/icons-react';

const FamilyFriendListModal = ({ open, user, onClose, onChange }) => {
    const [discaminoUsers, setDiscaminoUsers] = useState([]);
    const [noFriendUsers, setNoFriendUsers] = useState([]);

    // Control variables to display the checkbox checked or not and the selectable users
    const [displayDrivers, setDisplayDrivers] = useState(!user.piloto);
    const [displayCodrivers, setDisplayCodrivers] = useState(user.piloto);
    
    // Input text to search for users
    const [inputUser, setInputUser] = useState('');
    const [inputFriendUser, setInputFriendUser] = useState('');

    // Selected user un on the selectable
    const [selectedUser, setSelectedUser] = useState(user);
    const [selectedUserId, setSelectedUserId] = useState(user.id);
    const [selectedFriendId, setSelectedFriendId] = useState(0);
    
    const loadUsers = useCallback(async (userId) => {
        // Load all the users
        let drivers = await getAllUsers(true);
        let codrivers = await getAllUsers(false);
        let discaminoUsers = drivers.concat(codrivers);
        setDiscaminoUsers(discaminoUsers);

        // Filter the users that are already friends and the current user
        let noFriendUsers = discaminoUsers.filter(u => u.id !== userId).filter(u => !user.amigos.find(f => f.id === u.id));
        setNoFriendUsers(noFriendUsers);
    }, [user]);

    useEffect(() => {
        loadUsers(selectedUserId);
    }, [loadUsers, selectedUserId]);

    const headerData = [
        {
          header: 'NOMBRE',
          key: 'nombre',
        },
        {
            header: 'TIPO',
            key: 'tipo',
        },
        {
            header: 'MODIFICAR',
            key: 'modificar',
        }
    ];

    const updatePriority = (friendId, order) => {
        updateFriendship(selectedUserId, friendId, order);
        loadUsers();
    }

    function friendsTableRows() {
        if (discaminoUsers.length === 0) return []; // No users loaded yet

        let user = discaminoUsers.find(u => u.id === selectedUserId);
        var friends = user.amigos;
        let newFriends = [];

        friends.map((friend, index) => {
            if (!displayDrivers && friend.piloto) return friend;
            if (!displayCodrivers && !friend.piloto) return friend;

            let newFriend = {
                id: String(friend.id),
                nombre: friend.nombre,
                tipo: friend.piloto ? 'Piloto' : 'Copiloto',
                modificar: 
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {/* Prioridad: {friend.order} */}

                        {index !== 0 ?
                            <Button style={{marginLeft: '2px'}}
                                onClick = {() => {
                                    updatePriority(friend.id, friends[index-1].order); // Update the order of the friend to the previous one
                                    updatePriority(friends[index-1].id, friends[index-1].order+1) // Update the order of the previous friend to the old + 1
                            }}><ArrowUp32/></Button>
                            : index === 0 &&
                            <Button style={{marginLeft: '2px'}} disabled={true}><ArrowUp32/></Button>                            
                        }                     
                        {index !== friends.length-1 ?
                            <Button style={{marginLeft: '2px'}}
                                onClick = {() => {
                                    updatePriority(friend.id, friends[index+1].order); // Update the order of the friend to the next one
                                    updatePriority(friends[index+1].id, friends[index+1].order-1) // Update the order of the next friend to the old - 1
                            }}><ArrowDown32/></Button>
                            : index === friends.length-1 &&
                            <Button style={{marginLeft: '2px'}} disabled={true}><ArrowDown32/></Button>    
                        }
                        <div>
                            <Button className = "delete-friend-button" onClick = {() => {deleteFamilyFriend(selectedUserId, friend.id)}}><TrashCan32 /></Button>
                        </div>
                    </div>
            }

            newFriends.push(newFriend)
            return newFriend
        })

        newFriends = newFriends.sort((a, b) => {
            if (a.tipo === 'Piloto' && b.tipo === 'Copiloto') return -1;
            if (a.tipo === 'Copiloto' && b.tipo === 'Piloto') return -1;
            return 0;
        });

        return newFriends;
      }

    const filterNoFriendsList = () => {
        let filteredUsers = [];
        
        filteredUsers = filteredUsers.concat(noFriendUsers.filter(u => u.piloto && displayDrivers));
        filteredUsers = filteredUsers.concat(noFriendUsers.filter(u => u.copiloto && displayCodrivers));

        if (inputFriendUser) {
            filteredUsers = filteredUsers.filter(u => (u.nombre + " " + u.apellidos).toLowerCase().startsWith(inputFriendUser.toLowerCase()));
        }

        // Sort the list of by name
        filteredUsers = filteredUsers.sort((a, b) => {
            if (a.nombre < b.nombre) return -1;
            if (a.nombre > b.nombre) return 1;            
            if (a.apellidos < b.apellidos) return -1;
            if (a.apellidos > b.apellidos) return 1;
            
            return 0;
        });
    
        return filteredUsers;
    }

    async function addFamilyFriend(userId, friendId) {
        await addFriend(userId, friendId);
        loadUsers();
    }

    async function deleteFamilyFriend(userId, friendId) {
        await deleteFriend(userId, friendId);
        loadUsers();
    }

    return (
        <Modal
            open={open}
            modalLabel="Lista de familiares y amigos"
            modalHeading={"Familiares y amigos de " + selectedUser.nombre + " " + selectedUser.apellidos + " " + (selectedUser.piloto ? "(Piloto)" : "(Copiloto)")}
            primaryButtonText="Close"
            passiveModal={true}
            onRequestClose={onClose}
            className="train-modal">
            
            <h5 className = "title-addfriend">Seleccionar usuario al que modificar amigos</h5>
            <div className="bx--grid">
                <TextInput
                    id="user-input"
                    helperText="Selecciona un usuario de la lista"
                    labelText="Filtrar usuarios por nombre y apellidos"
                    placeholder="Buscar usuario"
                    value={inputUser}
                    onChange={(event) => {
                        setInputUser(event.target.value);
                        const findmethod = (u) => {
                            const full_name = u.nombre + " " + u.apellidos;
                            return full_name.toLowerCase().startsWith(event.target.value.toLowerCase());
                        }

                        const search = discaminoUsers.find(findmethod);
                        if (search !== undefined) {
                            setSelectedUser(search);
                            setSelectedUserId(search.id);
                        } else {
                            setSelectedUser(user);
                            setSelectedUserId(user.id);
                        }
                    }}
                />

                <Select
                    id="select-user"
                    defaultValue={0}
                    labelText=""
                    light={true}
                    onChange={(event) => {
                        setSelectedUserId(parseInt(event.target.value));
                        setSelectedUser(discaminoUsers.find(u => u.id === parseInt(event.target.value)))
                    }}>
                        {!inputUser && <SelectItem value={0} disabled={true} text="Selecciona un usuario" />}
                        {discaminoUsers.filter(u => (u.nombre + " " + u.apellidos).toLowerCase().startsWith(inputUser.toLowerCase())).map(user =>
                            <SelectItem
                                key={user.id}
                                value={user.id}
                                text={(user.apellidos ? user.nombre + " " + user.apellidos : user.nombre) + " " + (user.piloto ? "(Piloto)" : "(Copiloto)")}/>
                        )}
                </Select>
            </div>

            <div className="train-modal__checkboxes">
                <Checkbox 
                    id="drivers-checkbox" 
                    checked={displayDrivers} 
                    labelText="Mostrar pilotos" 
                    onChange={() => {
                        setDisplayDrivers(!displayDrivers);
                    }}/>

                <Checkbox 
                    id="codrivers-checkbox" 
                    checked={displayCodrivers} 
                    labelText="Mostrar copilotos" 
                    onChange={() => {
                        setDisplayCodrivers(!displayCodrivers);
                    }}/>
            </div>
            {
                <div className="table-container">
                    <DataTable rows={friendsTableRows(selectedUserId)} headers={headerData} >
                        {({ rows, headers, getHeaderProps, getTableProps }) => (
                            <TableContainer title="">
                                <Table {...getTableProps()}>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map(header => (
                                                <TableHeader {...getHeaderProps({ header })}>
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow key={row.id}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <TableCell key={cell.id}>
                                                            {cell.value}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DataTable>
                </div>
            }
            
            <h5 className = "title-addfriend">Añadir usuarios a la lista</h5>
            <div className="bx--grid">
                <TextInput
                    helperText="Selecciona un usuario de la lista"
                    id="friend-input"
                    labelText="Filtrar usuarios por nombre y apellidos"
                    placeholder="Buscar usuario"
                    value={inputFriendUser}
                    onChange={(event) => {
                        setInputFriendUser(event.target.value);
                        const findmethod = (u) => {
                            const full_name = u.nombre + " " + u.apellidos;
                            return full_name.toLowerCase().startsWith(event.target.value.toLowerCase());
                        }
                        const search = noFriendUsers.find(findmethod);
                        if (search !== undefined) {
                            setSelectedFriendId(search.id);
                        } else {
                            setSelectedFriendId(0);
                        }
                    }}
                />

                <Select
                    id="select-friend"
                    defaultValue={0}
                    labelText=""
                    light={true}
                    onChange={(event) => {setSelectedFriendId(event.target.value)}}>
                        {!inputFriendUser && <SelectItem value={0} disabled={true} text="Selecciona un usuario" />}
                        {filterNoFriendsList().map(user =>
                            <SelectItem key={user.id} value={user.id} text={user.apellidos ? user.nombre + " " + user.apellidos : user.nombre}/>
                        )}
                </Select>
                <Button className = "add-friend-button" disabled={selectedFriendId === 0} onClick = {() => {addFamilyFriend(selectedUserId, selectedFriendId)}}>Añadir <DocumentAdd32 /></Button>
            </div>
        </Modal>
    );
}

export default FamilyFriendListModal;