import React, {useState, useEffect} from 'react';
import './disponibility.scss';
import { useDispatch } from 'react-redux';


import {
    DatePicker,
    DatePickerInput,
    NumberInput,
    Button,
} from 'carbon-components-react';

import { getUser } from '../../../services/users';
import { getLocalLoginInfo } from '../../../reducers/loginReducer';
import { initTrains } from '../../../reducers/trainsReducer';

import moment from 'moment';

const Disponibility = (props) => {
    const today = moment();
    const dispatch = useDispatch()
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [disponibility, setDisponibility] = useState(props.value);
    const [selectedDate, setSelectedDate] = useState(props.date);
    const [user, setUser] = useState(undefined)

    useEffect(() => {

        dispatch(initTrains('all'))
        getUser(getLocalLoginInfo().userid).then((data) => {
          setUser(data)
        })

        if (selectedDate !== undefined && disponibility > 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [selectedDate, disponibility, dispatch]);

    const handleDateChange = (date) => {
        date = date[0];
        setSelectedDate(moment(date).format('L'));
    }

    const handleNumberChange = (event, direction) => {
        if (direction === 'up') {
            setDisponibility(disponibility + 1);
        } else if (direction === 'down') {
            setDisponibility(disponibility - 1);
        }
    };

    const handleSubmit = () => {
        console.log(selectedDate, disponibility);
        props.onSubmit(selectedDate, disponibility);
    }

    return (
        <div>
            {(user !== undefined && user.piloto === true && user.copiloto === false) &&
                <div className="disponibility-container">
                    {props.mode !== "small" && <p>Modificar disponibilidad</p>}
                    <div className="disponibility-fields">
                        {props.date === undefined &&
                            <DatePicker className="disponibility-field" datePickerType="single" dateFormat="d/m/Y" minDate={today.format('L')} onChange={(date) => handleDateChange(date)}>
                                <DatePickerInput
                                    placeholder="dd/mm/yyyy"
                                    disabled={props.disabled}
                                    labelText={props.mode !== "small" && "Día del entreno"}
                                    id="date-picker-single"/>
                            </DatePicker>
                        }
                        <div className="train-day-header-field">Límite de entrenos:</div>
                        <NumberInput 
                            className="disponibility-field" 
                            id="dispnibility-number-input" 
                            label={props.mode !== "small" && "Turnos disponible"}
                            min={0}
                            value={disponibility}
                            disabled={props.disabled}
                            onChange={(event, direction) => handleNumberChange(event, direction)}/>

                        {buttonDisabled ? 
                            <Button className="disponibility-field" size="small" disabled onClick={handleSubmit}>Guardar</Button>
                            :
                            <Button className="disponibility-field" size="small" onClick={handleSubmit}>Guardar</Button>
                        }
                    </div>
                </div>
            } 
        </div>
    );
};

export default Disponibility;