import React from 'react'
import './popUpComponent.scss';
import {
  Button
} from 'carbon-components-react';
import { trainValidation } from '../../reducers/trainsReducer';
import { useDispatch } from 'react-redux';

function PopUpComponent(props){
  const dispatch = useDispatch()
  const validarEntrenos = (day) => { 
    dispatch(trainValidation(day))
    // props.setTrigger(false)
  }
  return (props.trigger) ? (
    <div className="popup">
      <div className="popup-inner">
        {props.children}
        <Button className="calendar-button" onClick={()=> props.setTrigger(false)}>CANCELAR</Button>
        <Button className="calendar-button" onClick={()=> validarEntrenos(props.id_train, props.setTrigger(false))}>CONFIRMAR</Button>
      </div>
    </div>
  ) : "";
}

export default PopUpComponent