import React, { useState, useEffect } from 'react';
import './userCard.scss';
import Users  from '../../Usuarios/Users/Users';
import axios from 'axios';

import InfiniteScroll from 'react-infinite-scroll-component';
import { getAuthenticationHeader } from '../../../reducers/loginReducer';
// Tarjeta que muestra los datos de cada usuario
const UserCard = ({bool}) => {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const api_url = process.env.REACT_APP_BACKEND_URL;
    const base_url = `${api_url}/api/usuarios`;
    const usersAux = [];
    
    useEffect(() => {
        const loadUsers = async () => {
          try {
            setIsLoading(true);
            const response = await axios.get(`${base_url}/?pilotos=${bool}&page=${page}`, {headers: getAuthenticationHeader().headers});
            console.log(response);
            console.log(response.data.results);

            // Fitramos para que los usuarios que pueden ser pilotos y copilotos no aparezcan en la lista de pilotos.
            for (var user of response.data.results){
              if(bool === true && (user.piloto === true && user.copiloto === false)){
                usersAux.push(user)
              }
              else if(bool === false && user.copiloto === true){
                usersAux.push(user)
              }
            }

            setUsers((users) => [...users, ...usersAux]);
            setUsers((users) => users.sort(sortArray));
            setErrorMsg('');
          } catch (error) {
            if (page === 0) {
              setErrorMsg('Error while loading data. Please try again later.');
            }
          } finally {
            setIsLoading(false);
          }
        };
    
        loadUsers();
      }, [page, base_url, bool]);
    
      const loadMore = () => {
        setPage((page) => page + 1);
      };

      function sortArray(x, y){
        if (x.nombre < y.nombre) {return -1;}
        if (x.nombre > y.nombre) {return 1;}
        return 0;
      }

    return(
        <div> 
            <Users users={users} />
            {errorMsg && <p className="errorMsg">{errorMsg}</p>}
            <InfiniteScroll dataLength={users.length}
            next={loadMore}
            hasMore={true}
            loader={isLoading && <p>Cargando...</p>}
            ></InfiniteScroll>
        </div>
    );    
};
export default UserCard;