import React, { useEffect, useState } from 'react';
import './coupleCard.scss';
import { useDispatch } from 'react-redux';
import { updateCouple } from '../../../reducers/couplesReducer';

import {
    Bicycle32,
    Group32
} from '@carbon/icons-react';

import {
    Select,
    SelectItem,
    Tile
} from 'carbon-components-react'
import { trigger } from '../../../events';

// Content
function Couple({ couple, filter, day, unassignedUsers}) {
    const id_nonpiloto = '';
    const id_noncopiloto = '';
    const dispatch = useDispatch()

    const [pareja, setPareja] = useState(couple)
    
    const [pilotos, setPilotos] = useState([])
    const [copilotos, setCopilotos] = useState([])

    useEffect(() => {
        if (`${pareja.bicicleta}` in unassignedUsers) {
            setPilotos(unassignedUsers[parseInt(pareja.bicicleta)]['pilotos'])
            setCopilotos(unassignedUsers[parseInt(pareja.bicicleta)]['copilotos'])
        }
        
    }, [dispatch, day, unassignedUsers, pareja.bicicleta])

    const action = title => async (event) => {
        let edited_couple = pareja
        if (title === 'changePiloto') {
            edited_couple.usuario_piloto = parseInt(event.target.value)
        } else if (title === 'changeCopiloto') {
            edited_couple.usuario_copiloto = parseInt(event.target.value)
        }

        try {
            const data = await updateCouple(edited_couple)
            setPareja(data)
            trigger(`root:fetch-libres-${day}`, {})
        } catch (error) { }
    };

    if (pareja.bicicleta === parseInt(filter) || parseInt(filter) === 0) {

        return (
            <div key={pareja.id} className="bx--col">
                <Tile className="tarjeta">
                    <div>
                        <Group32 color="#0082ce" />
                        {/* Pilotos select */}
                        <Select id="select-users" defaultValue="placeholder-item" labelText="Piloto" light={true} onChange={action('changePiloto')}>
                            <SelectItem value={pareja.usuario_piloto !== null ? pareja.usuario_piloto : id_nonpiloto} text={pareja.usuario_piloto !== null ? pareja.piloto : 'Ningún Piloto'} />
                            {
                                //Navegamos en el objeto json de piloto para sacar datos necesarios
                                pilotos.map(piloto =>
                                    <SelectItem key={piloto.id} value={piloto.id} text={piloto.nombre} />)
                            }
                            {pareja.usuario_piloto !== null && <SelectItem value={id_nonpiloto} text='Ningún Piloto' />}
                        </Select>

                        {/* Copilotos select */}
                        <Select id="select-1" defaultValue="placeholder-item" labelText="Copiloto" light={true} onChange={action('changeCopiloto')}>
                            <SelectItem value={pareja.usuario_copiloto !== null ? pareja.usuario_copiloto : id_noncopiloto} text={pareja.usuario_copiloto !== null ? pareja.copiloto : 'APAMP/ALENTO'} />
                            {
                                //Navegamos en el objeto json de copiloto para sacar datos necesarios
                                copilotos.map(copiloto =>
                                    <SelectItem key={copiloto.id} value={copiloto.id} text={copiloto.nombre} />)
                            }
                            {pareja.usuario_copiloto !== null && <SelectItem value={id_noncopiloto} text='Ningún copiloto' />}
                        </Select>

                        <Bicycle32 color="#0082ce" /><p >{pareja.nombre_bicicleta}</p>
                        <br />
                    </div>
                </Tile>
            </div>)
    } else {
        return <></>
    }

}

// Container
// Tarjeta que muestra la pareja que va a asistir a un entreno
const CoupleAdminCard = (props) => {
    return (
        <Tile>
            <div className="bx--col">
                {props.couples.map(couple =>
                    <Couple key={couple.id} couple={couple} filter={props.filter.filter} day={props.day} unassignedUsers={props.unassignedUsers}/>)}
            </div>
        </Tile>
    );
};
export default CoupleAdminCard;
