import React from 'react';
import ReactPlayer from 'react-player';
import image from '../../imgs/logo_discamino_sin_fondo.png';

const LandingPage = () => {
  return <div>
            <h1 className="titles">BIENVENID<img src={image} className="App-logo" alt="logo" />
            </h1>
            
            <ReactPlayer
              url='https://www.facebook.com/watch/?v=335780747573300'
              className='react-player'
              playing={true}
              muted
              width='100%'
              height='100%'
            />
            
         </div>;
};
export default LandingPage;