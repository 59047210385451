import React from 'react';
import './trainDay.scss';

import {
    Toggle,
    Tag,
} from 'carbon-components-react';

import moment from 'moment';
import { getLocalLoginInfo } from '../../../reducers/loginReducer';
import Disponibility from '../Disponibility';
import { createDisponibility } from '../../../services/users';
import { anotarseEntreno, desanotarseEntreno } from '../../../services/trains';

const toggleProps = (abierto, anotado) =>  {
    let disabled = false
    let defaultToggled = false
  
    if (!abierto) {
      disabled = true
    }
  
    if (anotado) {
      defaultToggled = true
    }
  
    return ({
      labelA: 'No',
      labelB: 'Si',
      disabled: disabled,
      defaultToggled: defaultToggled
    });
}

const TrainDay = (props) => {
    const getDisponibility = () => {
        if (props.user.disponibilidades.length > 0) {
            let dayDisponibility = props.user.disponibilidades.find(element => moment(element.fecha).format('L') === props.date)
            if (dayDisponibility) {
                return dayDisponibility.disponibilidad
            }
        }

        return 0;
    }

    const trainDay = props.trainDay;
    let day_open = false;
    if (trainDay.entrenos.length > 0) {
        day_open = trainDay.entrenos[0].abierto;
    }

    let user_disponibility = getDisponibility();

    const submitDisponibility = (fecha, disponibilidad) => {
        createDisponibility(props.user.id, fecha, disponibilidad).then(() => {
            props.updateData();
        })
    }

    const handleToggles = (toggle_id, value) => {
        const turn_id = toggle_id.split('t')[1];
        if (value === true) {
            anotarseEntreno(turn_id).then(() => {
                props.updateData();
            });
        } else {
            desanotarseEntreno(turn_id).then(() => {
                props.updateData();
            });
        }   
    }

    const createToggle = (turn) => {
        const user_logged_id = getLocalLoginInfo().userid;
        let anotados = turn.anotados.pilotos
        anotados = anotados.concat(turn.anotados.copilotos)

        const anotado = anotados.find(element => element.id === user_logged_id) !== undefined;
        const toggle_id = 't' + String(turn.id);
        const toggle = <Toggle onToggle={(toggled) => handleToggles(toggle_id, toggled)} {...toggleProps(turn.abierto, anotado)} id={toggle_id}/>

        return toggle;
    }

    const createTag = (turn) => {
        const user_logged_id = getLocalLoginInfo().userid
        var anotados = turn.anotados.pilotos
        anotados = anotados.concat(turn.anotados.copilotos)
        
        var estado = "En proceso"
        var tag_color = "cyan"
        var anotado = anotados.find(element => element.id === user_logged_id) !== undefined
        var asignado = turn.parejas.find(element => (element.usuario_piloto === user_logged_id || element.usuario_copiloto === user_logged_id)) !== undefined
        
        if ((!asignado && turn.validado) || (!turn.abierto && !anotado)) {
          estado = "No Asignado"
          tag_color = "red"
        } else if (asignado && turn.validado) {
          estado = "Asignado"
          tag_color = "green"
        } else if (anotado && !turn.validado) {
          estado = "Anotado"
          tag_color = "purple"
        }

        const tag = <Tag type={tag_color} title="Clear Filter"> {estado} </Tag>
        return tag;
    }

    const createTurns = () => {
        if (trainDay.entrenos.length > 0) {
            const turns = trainDay.entrenos.map((turn, index) => {
                const turn_hour = moment(turn.fecha).format('HH:mm')

                return (
                    <div key={turn.id} className="train-day-body">
                        <div className="train-day-body-field">{turn.nombre}</div>
                        <div className="train-day-body-field">{turn_hour}</div>
                        <div className="train-day-body-field">{createToggle(turn)}</div>
                        <div className="train-day-body-field">{createTag(turn)}</div>
                        {/* <div className="train-day-body-field">Disponible: {user_disponibility}</div> */}
                        <div className="train-day-body-field">Anotados: {turn.anotados.pilotos.length + turn.anotados.copilotos.length}</div>
                    </div>
                )
            });

            return turns;
        } else {
            return (
                <div className="train-day-body">
                    <div className="train-day-body-field">No hay turnos para este día</div>
                </div>
            )
        }
    }

    return (
        <div className="train-day">
            <div className="train-day-header">
                <div className="train-day-header-field">Día: {props.date}</div>
                <div className="train-day-header-disponibility">
                  <Disponibility mode="small" date={props.date} onSubmit={submitDisponibility} disabled={!day_open} value={user_disponibility}/>
                </div>
              <div className="train-day-header-field">Estado: {day_open ? "Abierto" : "Cerrado"}</div>
            </div>
            {createTurns()}
        </div>
    )
};

export default TrainDay;