import React from 'react';
import './train.scss';

//Fechas
import moment from 'moment';
import 'moment/locale/es';
import CalendarDay from '../CalendarDay';

// Tarjeta que muestra el día de  entreno, dentro de esta se montraran los entrenos en concreto y 
// las parejas que los componen
const Train = (props) => {

  moment.locale('es');
  const daysShown = 3;        //Días a mostrar
  const calendarDays = [];
  const calendarDayNames = [];

  let day = moment();
  day = day.add(props.calendarPage*daysShown, 'days');

  for (let i = 0; i < daysShown; i++) {
    
    if (day.format('dddd') !== "0") {
      calendarDays[i] = day.format('L');
      calendarDayNames[i] = day.format('dddd');
    } else {
      day = day.add(1, 'days')
      calendarDays[i] = day.format('L');
      calendarDayNames[i] = day.format('dddd');
    }

    day = day.add(1, 'days');
  }

  function createTable() {

    let table = []

    for (let i = 0; i < daysShown; i++) {
      table.push(
        <CalendarDay key={calendarDays[i]} user={true} calendarPage={props.calendarPage} filter={props.filter} day={calendarDays[i]} dayName={calendarDayNames[i]} />
      )
    }
    return table;
  }

  return (
    <div className="bx--row">
      {createTable()}
    </div>
  )
};
export default Train;