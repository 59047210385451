import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import './ExtraPilotsModal.scss'

import moment from 'moment';
import 'moment/locale/es';

import { getBicicletasUtilizadas, crearpareja, eliminarPilotoExtra } from '../../../services/trains';

import {
    Modal,
    DataTable,
    TableContainer,
    Table,
    TableHead,
    TableHeader,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Select,
    SelectItem,
} from 'carbon-components-react';

const ExtraPilotsModal = ({ open, turn, onClose}) => {
    const fecha = moment(turn.fecha).format('DD/MM/YYYY');
    const hora = moment(turn.fecha).format('LT');
    const bikes = useSelector(state => state.bikes);
    const inputRef = useRef();
    const [pilotosExtra, setPilotosExtra] = useState(turn.pilotos_sobrantes.pilotos);
    const [pilotosExtraAux, setPilotosExtraAux] = useState(turn.pilotos_sobrantes.pilotos);
    const [bicicletasUtilizadas, setBicicletasUtilizadas] = useState();
    const [bicicletasDisponibles] = useState(bikes);
    const [selectedBikeId, setSelectedBikeId] = useState(0);
    
    const loadBikes = useCallback(async (trainId) => {
        // Load all the used bikes
        let data = await getBicicletasUtilizadas(trainId);
        setBicicletasUtilizadas(data);
    }, []);

    useEffect(() => {
        loadBikes(turn.id);
    },[loadBikes]);

    const getBicicletasDisponibles = () =>{
        let bicicletas_disponibles = [];       
        if(bicicletasUtilizadas != undefined){
            for (let bike of bicicletasDisponibles){
                let utilizada = 0;
                for(let index = 0; index < bicicletasUtilizadas.length; index++){
                    if (bike.id === bicicletasUtilizadas[index].bicicleta){
                        utilizada += 1;
                    }
                }
                bicicletas_disponibles.push(<p key={String(bike.nombre)}> {bike.nombre + ": " + (bike.cantidad-utilizada)} </p>);
            }
        }
        return bicicletas_disponibles;
    }

    const getUserBikes = (user) => {
        let bikes = []
        const bikes_no = Object.keys(user.bicicletas).length;
        for (let index = 0; index < bikes_no; index++) {
          bikes.push(<p key={String(user.bicicletas[index].nombre)}> {"- " + user.bicicletas[index].abreviatura} </p>);
        }

        return bikes;
    }

    const actualizarPilotosExtra = (id) => {
        let pilotosExtraDef = [];
        for (let pilotoExtra of pilotosExtraAux){
            if(pilotoExtra.id != id){
                pilotosExtraDef.push(pilotoExtra);
            }
        }
        setPilotosExtra(pilotosExtraDef);
        setPilotosExtraAux(pilotosExtraDef);
    }

    const crearPareja = async (turnId, pilotoId, copilotoId, bicicletaId) =>{
        await crearpareja(turnId, pilotoId, copilotoId, bicicletaId);
        eliminarPilotoExtra(turnId, pilotoId);
        actualizarPilotosExtra(pilotoId);
        setSelectedBikeId(0);
    }

    function getTableRows(pilotosExtra) {
        let rows = [];
        var cualidad = "";

        for (let i = 0; i < pilotosExtra.length; i++) {            
            const user = pilotosExtra[i];            
            if(user.fuerte === true){
                cualidad = "Fuerte";
            }else if(user.normal === true){
                cualidad = "Normal";
            }
            else if(user.debil === true){
                cualidad = "Debil";
            }

            const row = {
                id: String(user.id), 
                nombre: user.nombre + " " + user.apellidos,
                cualidad: cualidad,  
                bicicletas: getUserBikes(user),
                crearPareja: <Button className="button-add-trainingPartner" disabled={!selectedBikeId} onClick = {() => {crearPareja(turn.id, user.id, 0, selectedBikeId);setDefaultSelectValue()}}>Crear pareja {/* <AddAlt16 /> */}</Button>
            }; 
            rows.push(row);
        }

        rows.sort((a, b) => { return a.nombre.localeCompare(b.nombre) });
        return rows;
    }

    const headerData = [
        {
          header: 'NOMBRE Y APELLIDOS',
          key: 'nombre',
        },
        {
            header: 'CUALIDAD',
            key: 'cualidad',
        },
        {
            header: 'BICICLETAS',
            key: 'bicicletas',
        },
        {
            header: 'CREAR PAREJA (APAMP/ALENTO)',
            key: 'crearPareja',
        },

      ];

    const handleChange = (bikeId) => {
        let pilotos_sobrantes = pilotosExtraAux;
        let pilotosExtra = [];
        
        for (let i = 0; i < pilotos_sobrantes.length; i++) {
            let user = pilotos_sobrantes[i];
            let bicicletas = pilotos_sobrantes[i].bicicletas;
            for(let bicicleta of bicicletas){
                if(bicicleta.id == bikeId){
                    pilotosExtra.push(user);
                }
            }
        }
        setPilotosExtra(pilotosExtra);

    }

    function setDefaultSelectValue(){
        inputRef.current.focus();
        inputRef.current.value = 0;
    }

    return (
        <Modal
            open = {open}
            modalLabel = "Pilotos sobrantes"
            modalHeading={turn.nombre + " - " + fecha + " - Hora: " + hora}
            primaryButtonText="Close"
            passiveModal={true}
            onRequestClose={onClose}
            className="extra-pilots-modal">
            <div className="lista-bicicletas-disponibles">
                <p><strong>Bicicletas Disponibles:</strong></p>
                {getBicicletasDisponibles()}
            </div>
    
            <h5 className = "title-selectBike">Seleccionar una bicicleta para crear la pareja con copiloto de APAM o ALENTO</h5>
            <div>
                <div className="bx--grid">
                    <Select
                        id="select-bikes"
                        defaultValue="placeholder-item"
                        labelText=""
                        ref={inputRef}
                        light={true}
                        onChange={(event) => {
                            if(event.target.value == 0){
                                setSelectedBikeId(0)
                                setPilotosExtra(pilotosExtraAux);
                            }else{
                                handleChange(event.target.value);
                                setSelectedBikeId(event.target.value);
                            }
                        }} >
                        <SelectItem value={0} text="Todas las bicicletas" />
                        {bikes.map(bike =>
                            <SelectItem key={bike.id} value={bike.id} text={bike.nombre} />)
                        }
                    </Select>
                </div>
            </div>
   
                <div className="table-container">
                    <h4>Pilotos Extra: {pilotosExtra.length}</h4>
                    <DataTable rows={getTableRows(pilotosExtra)} headers={headerData} >
                        {({ rows, headers, getHeaderProps, getTableProps }) => (
                            <TableContainer title="">
                                <Table {...getTableProps()}>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map(header => (
                                                <TableHeader {...getHeaderProps({ header })}>
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map(row => (
                                            <TableRow key={"r"+row.id}>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <TableCell key={cell.id}>
                                                            {cell.value}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DataTable>
                </div>
                
        </Modal>
    );
}

export default ExtraPilotsModal;