import { usersReducer } from '../reducers/usersReducer';
import { trainsReducer } from '../reducers/trainsReducer';
import { createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { bikesReducer } from '../reducers/bikesReducer';
import { bikeFilterReducer } from '../reducers/bikeFilterReducer';
import { couplesReducer } from '../reducers/couplesReducer';
import { loginReducer } from '../reducers/loginReducer';

const applyMiddleware = require("redux").applyMiddleware

const reducer = combineReducers({
  users: usersReducer,
  trains: trainsReducer,
  bikes: bikesReducer,
  bikesFilter: bikeFilterReducer,
  couplesReducer: couplesReducer,
  loginReducer: loginReducer,
})

export const store = createStore(
  reducer, 
  applyMiddleware(thunk)
)

