import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initBikes, updateBike } from '../../reducers/bikesReducer';
import { NumberInput, Tile } from 'carbon-components-react';
import './SelectNumberBikes.scss';
// import {
//     CheckmarkOutline32
// } from '@carbon/icons-react';
const SelectBike = ({bike}) => {
    let stock = bike.cantidad
    const dispatch = useDispatch()

    const changeStoch = (event, direction) => {
        if(direction.value !== undefined){
            stock = direction.value
            bike.cantidad = stock
            dispatch(updateBike(bike))
        } else if (direction === "up"){
            const newStock = stock +1
            stock = newStock
            bike.cantidad = stock
            dispatch(updateBike(bike))
        } else if(direction === "down"){
            const newStock = stock - 1
            stock = newStock
            bike.cantidad = stock
            dispatch(updateBike(bike))
        } 
    }
    
    return (<div key={bike.id} className="bx--grid">
        <div className="bx--row">
            {/* <Tile className="tarjeta-bike"> */}
            <div className="bike-style-title" style={{padding:"25px 5px 5px 5px"}}>
                <h5>{bike.abreviatura}</h5>
            </div>
            <div className="bike-style">
                <div className="bx--row" style={{margin:"1px"}}>
                <NumberInput
                    className="bx--number"
                    onChange={( event, direction) => changeStoch(event,direction)}
                    id={(bike.id).toString()}
                    max={100}
                    min={0}
                    step={1}
                    value={stock}
                />
            </div>
            </div>
        </div> 
        {/* </Tile> */}
    </div>)
}

const SelectNumberBikes = () => { 
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(initBikes())
    }, [dispatch]) 
    const state = useSelector(state => state); 

    return (<div>
        <Tile className="bikes">
        {state.bikes.map(bike =>
            <SelectBike key={bike.id} bike={bike}/>
        )}
     </Tile>    
    </div>
    );
};
export default SelectNumberBikes;