import {logout, makeLogin, refreshLogin} from '../services/login';

export const loginReducer = (state = [], action) => {
    let loginInfo = {
        refresh: "",
        access: "",
        userid: 0,
        admin: false
    }

    if(action.type === '@login/refreshToken'){
        loginInfo =  getLocalLoginInfo()
        loginInfo.access = action.payload
        setLocalLoginInfo(loginInfo)
        return action.payload
    }

    return state
}


export const loginAccess = (username, password) => {
    return makeLogin({username, password})
}

export const loginRefresh = () => {
    const loginInfo = getLocalLoginInfo()
    return refreshLogin(loginInfo.refresh, loginInfo.access)
}

export const getLocalLoginInfo = () => {
    let loginInfo = JSON.parse(window.localStorage.getItem('loginInfo'))

    if (loginInfo != null) return loginInfo
    loginInfo = {refresh: "", access: "", userid: 0, admin: false}

    return loginInfo
}

export const setLocalLoginInfo = (loginInfo) => {
    window.localStorage.setItem('loginInfo', JSON.stringify(loginInfo)) 
}

export const removeLocalLoginInfo = () => {
    window.localStorage.removeItem('loginInfo') 
}

export const makeLogout = () => {
    logout().then((data) => {
        removeLocalLoginInfo()
        window.location.replace('/')
    }).catch(() => {
        removeLocalLoginInfo()
        window.location.replace('/')
    })
}

export const getAuthenticationHeader = () => {
    const loginInfo = getLocalLoginInfo()
    const access = loginInfo.access
    const config = {
        headers: {
            Authorization: `Bearer ${access}`
        }
    }

    return config
}