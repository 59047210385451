import axios from 'axios';
import { getAuthenticationHeader } from '../reducers/loginReducer';

const api_url = process.env.REACT_APP_BACKEND_URL;
const base_url = `${api_url}/api/entrenos`;

export const getAll= async (calendarPage) => {
    const config = getAuthenticationHeader()

    const res = await axios.get(`${base_url}/?page=${calendarPage}`, {headers: config.headers})
    return res.data //turns
}

export const getFreeUsers = async (id, id_bicicleta) => {
    const config = getAuthenticationHeader()

    const res = await axios.get(`${base_url}/${id}/libres/?bicicleta=${id_bicicleta}`, { headers: config.headers })
    return res.data //usuarios libres
}


//establecemos funcion para obtener json de Usuarios pilotos libres por cada entreno y filtrado por bicicleta
export const getFreeUsersPiloto = async (id, id_bicicleta) => {
    const config = getAuthenticationHeader()
    const res = await axios.get(`${base_url}/${id}/libres/?bicicleta=${id_bicicleta}`, { headers: config.headers })
    return res.data.libres.pilotos
}

//establecemos funcion para obtener json de Usuarios copilotos libres por cada entreno y filtrado por bicicleta
export const getFreeUsersCopiloto = async (id, id_bicicleta) => {
    const config = getAuthenticationHeader()
    const res = await axios.get(`${base_url}/${id}/libres/?bicicleta=${id_bicicleta}`, { headers: config.headers })
    return res.data.libres.copilotos
}

export const updateEntreno = async (turn) => {
    const config = getAuthenticationHeader()

    const response = await axios.put(`${base_url}/${turn.id}/`, turn, config)
    return response.data
}

export const calcularEntreno = async (id) => {
    const config = getAuthenticationHeader()

    const response = await axios.get(`${base_url}/${id}/calcular/`, {headers: config.headers})
    return response
}
export const getBicicletasUtilizadas = async (id) => {
    const config = getAuthenticationHeader()

    const response = await axios.get(`${base_url}/${id}/bicicletas/`, {headers: config.headers})
    return response.data.Bicicletas
}

export const anotarseEntreno = async (id) => {
    const config = getAuthenticationHeader()

    const response = await axios.put(`${base_url}/${id}/anotar/`, {}, config)
    return response
}

export const desanotarseEntreno = async (id) => {
    const config = getAuthenticationHeader()

    const response = await axios.put(`${base_url}/${id}/desanotar/`, {}, config)
    return response
}

export const inicializarEntrenos = async (day, verano) => {
    const config = getAuthenticationHeader()

    const response = await axios.put(`${base_url}/iniciardia/?dia=${day}&verano=${verano}`, {}, config)
    return response
}

export const crearpareja = async (entrenoId, pilotoId, copilotoId, bicicletaId) => {
    const config = getAuthenticationHeader()

    const body = {
        pilotoId: pilotoId,
        copilotoId: 0,
        bicicletaId: bicicletaId
    }

    const response = await axios.post(`${base_url}/${entrenoId}/crearpareja/`, body, config)
    return response
}

export const eliminarPilotoExtra = async (id, pilotoId) => {
    const config = getAuthenticationHeader()

    const response = await axios.put(`${base_url}/${id}/eliminarPilotoExtra/?pilotoId=${pilotoId}`, {}, config)
    return response
}