import React, { useState } from 'react';
import './calendarDay.scss';
import Turn from '../Turn/Turn';
import { useDispatch, useSelector } from 'react-redux';
import { initTrains, calculoEntrenos, trainState, trainUnvalidation } from '../../../reducers/trainsReducer';
import PopUpComponent from '../../PopUpComponent/';
import SelectNumberBikes from '../../SelectNumberBikes';

import {
    Tile,
    Button
} from 'carbon-components-react';

import {
    Locked32,
    Unlocked32
} from '@carbon/icons-react';
import { inicializarEntrenos } from '../../../services/trains';

const CalendarDay = (props) => {

    const state = useSelector(state => state);
    let [validados] = useState(false);
    let [abiertos] = useState(false);

    const [show, setShow] = useState(false)
    const [buttonPopUp, setButtonPopup] = useState(false)
    const dispatch = useDispatch();

    let estadoTurnos = "ABRIR"

    const changeAbierto = async (day) => {
        dispatch(trainState(day))
    }
    
    const invalidarEntrenos = (day) => { 
        dispatch(trainUnvalidation(day))
    }

    function calcularParejas(day) {
        calculoEntrenos(state.trains, day).then(() => dispatch(initTrains(props.calendarPage)))
        setEnableVal(true)
    }

    function crearEntrenos(day, verano) {
        inicializarEntrenos(day, verano).then(() => dispatch(initTrains(props.calendarPage)))
    }

    if (props.day in state.trains) {
        validados = state.trains[props.day].validados
        abiertos = state.trains[props.day].abiertos
    }

    function formatDayName(dayName) {
        dayName.charAt(0).toUpperCase();
        dayName.slice(1);
        return (dayName.charAt(0).toUpperCase() + dayName.slice(1))
    }

    function hasTrains() {
        if (!(props.day in state.trains)) {
            return false
        }
        if (state.trains[props.day].entrenos.length) {
            return true;
        }
        return false;
    }

    const [enable, setEnable] = useState(false)
    function enableCalcular() {
        setEnable(true)
        setShow(!show)
    }

    const [enableValidated, setEnableVal] = useState(false)

    if (abiertos) {
        estadoTurnos = "CERRAR"
    }

    return (
        <div className="bx--col">
            {validados || (validados && props.user) ?
                (
                    <div>
                        <Tile className="calendar-tile">
                            <h1 className="day-name">{formatDayName(props.dayName)}</h1>
                            <h2 className="day-number">{props.day}</h2>
                            <Turn filter={props.filter} day={props.day} />
                            {!props.user && (
                                <Button className="calendar-button" onClick={() => invalidarEntrenos(props.day)}>MODIFICAR</Button>
                            )}
                        </Tile>
                    </div>
                ) : (
                    <>
                        {!props.user && (
                            <div>
                                <Tile className="calendar-tile">
                                    <h1 className="day-name">{formatDayName(props.dayName)}</h1>
                                    <h2 className="day-number">{props.day}</h2>
                                    <Turn filter={props.filter} day={props.day} />
                                    {hasTrains() ?
                                        (<>
                                            {abiertos ? (
                                                <div>
                                                    <Unlocked32 color="#0082ce" />
                                                    <br />
                                                    <div className="button-div">
                                                        <Button className="calendar-button" onClick={() => changeAbierto(props.day)} >{estadoTurnos}</Button>
                                                        <Button disabled className="calendar-button">CALCULAR</Button>
                                                        <Button disabled className="calendar-button">VALIDAR</Button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Locked32 color="#0082ce" />
                                                    <div>
                                                        <div className="button-div">
                                                            <Button className="calendar-button" onClick={() => changeAbierto(props.day)} >{estadoTurnos}</Button>
                                                            {enable ? <Button className="calendar-button" onClick={() => calcularParejas(props.day)} >CALCULAR</Button>
                                                                : <Button disabled className="calendar-button" >CALCULAR</Button>}
                                                            {enableValidated ? <Button className="calendar-button" onClick={() => setButtonPopup(true)}>VALIDAR</Button> :
                                                                <Button disabled className="calendar-button">VALIDAR</Button>}
                                                            {buttonPopUp && (
                                                                <div>
                                                                    <PopUpComponent trigger={buttonPopUp} setTrigger={setButtonPopup} id_train={props.day}>
                                                                        <p> Una vez confirmados no se podrán realizar más cambios. </p>
                                                                        <br />
                                                                    </PopUpComponent>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="button-div">
                                                            {show ?
                                                                <div>
                                                                    <SelectNumberBikes />
                                                                    <Button className="calendar-button bikes-button" onClick={() => enableCalcular()}>CONFIRMAR BICICLETAS</Button>
                                                                </div> :
                                                                <Button className="calendar-button bikes-button" onClick={() => setShow(!show)}>MOSTRAR BICICLETAS DISPONIBLES</Button>                                                        
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }
                                        </>) : (
                                            <div>
                                                <Button className="calendar-button-entreno" onClick={() => crearEntrenos(props.day, true)} >CREAR ENTRENOS DE VERANO</Button>
                                                <Button className="calendar-button-entreno" onClick={() => crearEntrenos(props.day, false)} >CREAR ENTRENOS DE INVIERNO</Button>
                                            </div>
                                        )
                                    }
                                </Tile>
                            </div>
                        )}
                    </>
                )}
        </div>
    );
};
export default CalendarDay;