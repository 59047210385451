import axios from 'axios';
import { getAuthenticationHeader } from '../reducers/loginReducer';

const api_url = process.env.REACT_APP_BACKEND_URL;
const base_url = `${api_url}/api/usuarios`;
    
export const getAll = async ({bool, page = 0} = {}) => {
    const config = getAuthenticationHeader()
    const res = await axios.get(`${base_url}/?pilotos=${bool}&page=${page}`, {headers: config.headers})
    return res.data.results //users
}

export const getAllUsers = async (bool) => {
    const config = getAuthenticationHeader();
    let allUsers = [];
    let page = 1;
    let res = null

    do {
        const url = `${base_url}/?pilotos=${bool}&page=${page}`;
        res = await axios.get(url, {headers: config.headers})
        allUsers = allUsers.concat(res.data.results);
        page++;
    } while(res.data.next !== null);

    return allUsers; //All users
}

export const getUser = async (id) => {
    const config = getAuthenticationHeader()
    const res = await axios.get(`${base_url}/${id}/`, {headers: config.headers})
    return res.data
}

export const createDisponibility = async (userId, day, disponibility) => {
    const config = getAuthenticationHeader()
    const body = {
        day: day,
        disponibility: disponibility
    }

    const res = await axios.put(`${base_url}/${userId}/disponibilidad/`, body, {headers: config.headers})
    return res.data
}

export const changePswd = async (credentials) => {
    const config = getAuthenticationHeader()

    const new_password1 = credentials.new_password1
    const new_password2 = credentials.new_password2
    const res = await axios.put(`${base_url}/${credentials.userid}/changepass/`, {new_password1, new_password2}, config)
    return res.data
}

export const addFriend = async (id, friendId) => {
    const config = getAuthenticationHeader()

    const response = await axios.put(`${base_url}/${id}/addFriend/?id=${friendId}`, {}, config)
    return response
}

export const deleteFriend = async (id, friendId) => {
    const config = getAuthenticationHeader()

    const response = await axios.put(`${base_url}/${id}/deleteFriend/?id=${friendId}`, {}, config)
    return response
}

export const updateFriendship = async (id, friendId, order) => {
    const config = getAuthenticationHeader()
    const body = {
        id: friendId,
        order: order
    }

    const response = await axios.put(`${base_url}/${id}/updateFriendship/`, body, config)
    return response
}