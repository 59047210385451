import React from 'react';
import '../../pages/AyudaPage/_ayuda-page.scss';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  SelectItem,
  Tag,
  Toggle,
  Tile
} from 'carbon-components-react';
import {
  Bicycle32,
  Group32
} from '@carbon/icons-react';
import moment from 'moment'
import Disponibility from '../../components/Perfil/Disponibility';

const AyudaPage = () => {
  const day = moment()
  const today = day.format('L');
  const today_name = day.format("dddd").charAt(0).toUpperCase() + day.format("dddd").slice(1)

  const toggleProps = (abierto, anotado) =>  {
    let disabled = false
    let toggled = false
  
    if (!abierto) {
      disabled = true
    }
  
    if (anotado) {
      toggled = true
    }
  
    return ({
      labelA: 'No',
      labelB: 'Si',
      disabled: disabled,
      toggled: toggled
    });
  }

  return (
    <div className="bx--grid flex">
      <div className="flex-row">
        <h1>Mi Perfil</h1>
        <p>En la pestaña de "Mi Perfil" te podrás anotar al entrenamiento al que te gustaría asisitir, siempre y cuando el mismo se encuentre abierto a inscripciones.</p>
      </div>
      {/* <img className="margin" src={profileImage} alt="pestaña mi perfil" /> */}
      {
      <div>
        <Tile className="tarjeta">
          <div className="train-day">
            <div className="train-day-header">
              <div className="train-day-header-field">Día: 14/12/2021</div>
              <div className="train-day-header-limit">
                <div className="train-day-header-field-2"><Disponibility mode="small" date={14/12/2021} disabled/></div>
              </div>
              <div className="train-day-header-field">Estado: Abierto</div>
            </div>
            <div className="train-day-body">
              <div className="train-day-body-field">Turno 1</div>
              <div className="train-day-body-field">16:00</div>
              <div className="train-day-body-field"><Toggle {...toggleProps(true, true)} id='t1'/></div>
              <div className="train-day-body-field"><Tag type="purple" title="Clear Filter"> Anotado </Tag></div>
              <div className="train-day-body-field">Anotados: 10</div>
            </div>
            <div className="train-day-body">
              <div className="train-day-body-field">Turno 2</div>
              <div className="train-day-body-field">17:00</div>
              <div className="train-day-body-field"><Toggle {...toggleProps(false, true)} id='t2'/></div>
              <div className="train-day-body-field"><Tag type="green" title="Clear Filter"> Asignado </Tag></div>
              <div className="train-day-body-field">Anotados: 14</div>
            </div>
            <div className="train-day-body">
              <div className="train-day-body-field">Turno 3</div>
              <div className="train-day-body-field">18:00</div>
              <div className="train-day-body-field"><Toggle {...toggleProps(false, true)} id='t3'/></div>
              <div className="train-day-body-field"><Tag type="red" title="Clear Filter"> No Asignado </Tag></div>
              <div className="train-day-body-field">Anotados: 9</div>
            </div>
            <div className="train-day-body">
              <div className="train-day-body-field">Turno 4</div>
              <div className="train-day-body-field">19:00</div>
              <div className="train-day-body-field"><Toggle {...toggleProps(true, false)} id='t0'/></div>
              <div className="train-day-body-field"><Tag type="cyan" title="Clear Filter"> En proceso </Tag></div>
              <div className="train-day-body-field">Anotados: 16</div>
            </div>
          </div>
        </Tile>
        <p><strong>¡IMPORTANTE!</strong> !Habrá un recuadro con los entrenamientos para cada día!</p>
    </div>
          
      }

      {/* <h4>¿QUÉ SIGNIFICAN LAS COLUMNAS?</h4> */}
      <TableContainer className="margin table-container" title="ELEMENTOS IMPORTANTES DE CADA RECUADRO CON LOS ENTRENAMIENTOS">
        <Table useZebraStyles={true}>
          <TableBody>
            <TableRow>
              <TableCell className="table-header">ESTADO</TableCell>
              <TableCell>Le informa sobre el <u>estado del entrenamiento.</u> En el caso de estar ABIERTO se aceptarán inscripciones al entrenamiento.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-header">NOMBRE</TableCell>
              <TableCell>El <u>turno</u> de entrenamiento.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-header">FECHA</TableCell>
              <TableCell>El <u>día</u> del entrenamiento.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-header">HORA</TableCell>
              <TableCell>La <u>hora</u> de comienzo del entrenamiento.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-header">ASIGNAR LÍMITE DE ENTRENOS</TableCell>
              <TableCell>Contiene un botón para indicar el <u>máximo de entrenamientos</u> que podrás realizar en el respectivo día.<br /> De esta manera, se dará prioridad a las personas que sólo puedan salir un turno determinado.
              <strong> Solo disponible para pilotos.</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-header">APUNTARSE</TableCell>
              <TableCell>Contiene un botón para solicitar la <u>asistencia a un entrenamiento.</u><br /> Pulsando el mismo cambia de estado de "No" a "Sí".</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-header">ESTADO DE LA SOLICITUD DE UN ENTRENAMIENTO</TableCell>
              <TableCell>Columna que informa del <u>estado de vuestra solicitud.</u> A continuación se detallará el significado de cada etiqueta (En proceso, Anotado, No asignado, Asignado).</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p><strong>¡IMPORTANTE!</strong> Al solicitar un entrenamiento no quiere decir que se vaya a salir en las horas anotadas, solamente se informa al Capitán de tu disponibilidad para entrenar a las horas seleccionadas. ¡Cómo si le estuvieras enviando un mensaje por el grupo!</p>
      <br />
      <p>Para manteneros informados del estado de vuestra solicitud, os habéis de fijar en la columna donde os pueden aparecer las siguientes opciones:</p>

      <Table className="margin" useZebraStyles={true}>
        <TableBody>
          <TableRow>
            <TableCell><Tag type="cyan" title="Clear Filter"> En proceso </Tag></TableCell>
            <TableCell>La gente aún está solicitando entrenar y no se ha tomado ninguna decisión.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><Tag type="purple" title="Clear Filter"> Anotado </Tag></TableCell>
            <TableCell>Al seleccionar el botón de ANOTARSE, cambiar el mismo a “SI” automáticamente os cambia el estado a ANOTADO.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><Tag type="red" title="Clear Filter"> No Asignado </Tag></TableCell>
            <TableCell>No has sido seleccionado para entrenar en ese turno.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><Tag type="green" title="Clear Filter"> Asignado </Tag></TableCell>
            <TableCell>Has sido seleccionado para entrenar en ese turno. ¡Prepárate para sudar!</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className="flex-row">
        <h1>Calendario</h1>
        <p>En la pestaña de “Calendario” podrás ver todas las parejas que salen a entrenar en todos los turnos del día, junto con la bici que utilizarán.</p>
      </div>

      <Tile className="help-calendar-tile">
          <div className="bx--col">
            <p><strong>Puedes escoger la bici que te interese para ver las parejas que saldrán con ella, o también puedes ver todas al mismo tiempo seleccionando "Todas las bicicletas"</strong></p>
            <Select
              id="select-bikes"
              defaultValue="placeholder-item"
              labelText=""
              light={true}
              onChange={() => {}}>
              <SelectItem value={0} text="Todas las bicicletas" />
              <SelectItem key={1} value={1} text="Handbike-Silla" />

            </Select>
            {/* Calendar Tile*/}
            <Tile className="calendar-tile">
              <h1 className="day-name">{today_name}</h1>
              <h2 className="day-number">{today}</h2>
              
              {/* Turn Tile*/}
              <Tile>
                <div className="turn-title">
                  <h5 className="turn-title">Turno 1</h5>
                  <h5 className="turn-title">14:00</h5>
                </div>
                <div>
                  {/* Couple Tile*/}
                  <br/>
                  <p><strong>En cada tarjeta tienes la información del PILOTO, COPILOTO y BICI para cada turno y día</strong></p>
                  <Tile className="help-calendar-tile">
                    <div>
                        <Group32 color="#0082ce" />
                        <p ><u>Piloto:</u> Paula González</p>
                        <p ><u>Copiloto:</u> María Purificación</p>
                        <br/>
                        <Bicycle32 color="#0082ce" /><p>Handbike-Silla</p>
                        <br />
                    </div>
                  </Tile>
                </div>
              </Tile>
            </Tile>
          </div>
      </Tile>

      {/* <img className="margin" src={calendarImage} alt="pestaña calendario" /> */}
    </div>
  )
};
export default AyudaPage;