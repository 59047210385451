import {getAll, updatePareja} from '../services/couples';

export const couplesReducer = (state = [], action) => {
    if(action.type === '@couples/init'){
        return action.payload
    }

    // Editar los datos de una pareja
    if(action.type === '@couple/updatePiloto'){
        const {id, usuario_piloto} = action.payload
        return state.map(couple => {
            if(couple.id === id){
                couple.usuario_piloto = parseInt(usuario_piloto)
                updatePareja(couple)
            }
            return couple
        })
    }

    if(action.type === '@couple/updateCopiloto'){
        const {id, usuario_copiloto} = action.payload
        return state.map(couple => {
            if(couple.id === id){
                couple.usuario_copiloto = parseInt(usuario_copiloto)
                updatePareja(couple)
            }
            return couple
        })
    }
    return state
}

export const coupleStatePiloto = (id, usuario_piloto) => {  
    return {
        type: '@couple/updatePiloto',
        payload: {
            id,
            usuario_piloto
        }
    }
}

export const coupleStateCopiloto = (id, usuario_copiloto) => {  
    return {
        type: '@couple/updateCopiloto',
        payload: {
            id,
            usuario_copiloto
        }
    }
}

export const initCouples = () => {
    return async (dispatch) => {
        const couples = await getAll()
        dispatch ({
            type: '@couples/init',
            payload: couples,
        })
    }
}

export const updateCouple = (couple) => {
return updatePareja(couple)
}