import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './turn.scss';
import CoupleCard from '../../Calendario/CoupleCard/CoupleCard';
import CoupleAdminCard from '../../Calendario/CoupleCard/CoupleAdminCard';
// Gestión de abierto/cerrado
import {
    Button,
    Tile
} from 'carbon-components-react';

import { getLocalLoginInfo } from '../../../reducers/loginReducer';

//Fechas
import moment from 'moment';
import 'moment/locale/es';
import { freeUsers } from '../../../reducers/trainsReducer';
import { off, on } from '../../../events';
import TrainModal from '../TrainModal';
import ExtraPilotsModal from '../ExtraPilotsModal/ExtraPilotsModal';
//EndFechas

// Content
function TurnContent({ turn, filter, day }) {
    const bikes = useSelector(state => state.bikes)
    const [unassignedUsers, setUnassignedUsers] = useState({})
    const [displayExtraPilotsModal, setExtraPilotsModal] = useState(false)
    const [displayModal, setDisplayModal] = useState(false)
    const loadUnassignedUsers = useCallback(async () => {
        if (turn.abierto || turn.parejas.length === 0) return {}

        let users = {}
        for (let bike of bikes) {
            const data = await freeUsers(turn.id, bike.id)
            users[bike.id] = data.libres
        }

        setUnassignedUsers(users)
    }, [bikes, turn.id, turn.abierto, turn.parejas.length])

    const openExtraPilotsModal = () => {
        setExtraPilotsModal(true);
    }

    const closeExtraPilotsModal = () => {
        setExtraPilotsModal(false);
    }

    const openTrainModal = () => {
        setDisplayModal(true);
    }

    const closeTrainModal = () => {
        setDisplayModal(false);
    }

    useEffect(() => {
        loadUnassignedUsers()
        
        on(`root:fetch-libres-${day}`, () => loadUnassignedUsers())

        return () => {
            off(`root:fetch-libres-${day}`, () => loadUnassignedUsers())
        };
    }, [day, loadUnassignedUsers])

    const fecha = turn.fecha;
    const validado = turn.validado
    const hora = moment(fecha).format('LT');

    // Saber si es admin o no para mostrarle un entreno u otro
    let admin = false
    admin = getLocalLoginInfo().admin

    return <div className="bx--col">
        <Tile>
            <div>
                <div className="turn-title">
                    <h5 className="turn-title">{turn.nombre}</h5>
                    <h5 className="turn-title">{hora}</h5>
                    {turn.parejas.length !== 0 && turn.pilotos_sobrantes.pilotos.length !== 0 && !validado &&
                        <div>
                            <Button className="calendar-button-pilotos-extra" onClick={openExtraPilotsModal}>Pilotos extra</Button>
                            <ExtraPilotsModal open={displayExtraPilotsModal} turn={turn} onClose={closeExtraPilotsModal}/>
                        </div>      
                    }
                </div>

                {!admin ? <CoupleCard couples={turn.parejas} filter={{ filter }} /> :
                    (!validado ?
                        (turn.parejas.length !== 0 ? 
                            (<CoupleAdminCard couples={turn.parejas} turn_id={turn.id} filter={{ filter }} day={day} unassignedUsers={unassignedUsers}/>) :
                            (<Tile className="tarjeta">
                                <p>Copilotos anotados: {turn.anotados.copilotos.length}</p>
                                <p>Pilotos anotados: {turn.anotados.pilotos.length}</p>
                                <Button className="calendar-button-entreno" onClick={openTrainModal}>Mostrar usuarios</Button>
                                <TrainModal open={displayModal} turn={turn} onClose={closeTrainModal}/>
                            </Tile>
                            )
                        ) : (<CoupleCard couples={turn.parejas} filter={{ filter }} />))
                }
            </div>
        </Tile>
    </div>
}

// Tarjeta que muestra la hora del entreno, dentro las parejas que iran a dicho entreno

const Turn = ({ filter, day }) => {
    const state = useSelector(state => state) //devuelve todos los entrenos

    if (!(day in state.trains)) {
        return (<div className="bx--col"></div>);
    }

    return (
        <div className="bx--col">
            {state.trains[day].entrenos.map(turn =>
                <TurnContent filter={filter} key={turn.id} turn={turn} day={day} />)}
        </div>

    );
};
export default Turn;