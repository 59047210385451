import React from 'react';
import UserCard  from '../../components/Usuarios/UserCard/UserCard';
import '../../pages/PilotosPage/_pilotos-page.scss';

const PilotosPage = () => {
  return <div>
    {/* El scss del título está ubicado en _copilotos-page.scss ya que, al tener el mismo nombre, no es necesario replicar el mismo código en 2 archivos */}
    <h1 className="users-title">PILOTOS</h1> 
    <div className="bx--grid">
        <UserCard bool={true}/>
    </div>
  </div>
};
export default PilotosPage;