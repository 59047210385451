import React, { useState } from 'react';
import './Login.scss';
import image from '../../imgs/logo_discamino_sin_fondo.png';
import {
  Form,
  TextInput,
  Button,
  Tile,
} from 'carbon-components-react'
import { loginAccess, setLocalLoginInfo } from '../../reducers/loginReducer';
import { Redirect } from "react-router-dom";

function Login () {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [redirectToChangePass, setRedirectToChangePass] = useState(false)

  const [error, setError] = useState(false)
  const TextInputProps = {
    className: 'some-class',
    id: 'username',
    labelText: 'Nombre ususario',
    placeholder: 'Nombre usuario',
  };

  const PasswordProps = {
    className: 'some-class',
    id: 'password',
    labelText: 'Contraseña',
    placeholder:"Contraseña",
    showPasswordLabel: "Mostrar contraseña",
    hidePasswordLabel: "Ocultar contraseña"
  };
  
  const InvalidChangePasswordProps = {
    className: 'some-class',
    id: 'invalidPswd',
    invalid: true,
    invalidText:
      'Las credenciales son incorrectas',
  };

  const onLogin = (event) => { 
    event.preventDefault()
    if(username === "" || password === ""){
      setError(true)
    } else {
      loginAccess(username, password).then((loginInfo) => {
        setLocalLoginInfo(loginInfo)
        if(password === 'cambiame') {
          setRedirectToChangePass(true)
        } else {
          window.location.replace("/")
        }
      }).catch((err) => {
        setError(true)
      })
    }
  };

  return (
    <div style={ {display: 'flex', alignItems: 'center', justifyContent: 'center', height:'100vh', } }>
      {redirectToChangePass && <Redirect to="/change_pswd"/>}

      <Tile style={{padding: '1rem', width: '500px', height:'400px'}}>
      <Form className='$ui-background'>
        <img src={image} alt="logo" style={{width: '9rem'}}/>
        <div style={{margin: '1rem'}}>  
        {error ? <TextInput
             
            required
            light={true}
            value = {username} 
            {...InvalidChangePasswordProps}
            onChange = {({target}) => setUsername(target.value)}/> : 
            <TextInput
             
            required
            light={true}
            value = {username} 
            {...TextInputProps}
            onChange = {({target}) => setUsername(target.value)}/> }
          
        </div>
        <div style={{margin: '1rem'}}>
          {error ?
            <TextInput.PasswordInput value = {password} {...InvalidChangePasswordProps} onChange = {({target}) => setPassword(target.value)}/>
            :
            <TextInput.PasswordInput value = {password} {...PasswordProps} onChange = {({target}) => setPassword(target.value)}/>
          }
        </div>
        <Button type="submit" className="calendar-button" onClick = {(event) => onLogin(event)}>ACCEDER</Button>
      </Form>
      </Tile>
    </div>
  );
};    
export default Login;