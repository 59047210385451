import { getAll, updateEntreno, getFreeUsers, calcularEntreno, getFreeUsersPiloto, getFreeUsersCopiloto } from '../services/trains';

export const trainsReducer = (state = [], action) => {
    // Obtener el estado inicial de la aplicación respecto los datos de los entrenos
    if (action.type === '@trains/init') {
        return action.payload
    }

    // if (action.type === '@trains/isEmpty') {
    //     return action.payload
    // }

    if (action.type === '@trains/calcular') {
        const day = action.payload.day
        const access = action.payload.access
        state[day].abiertos = false
        state[day].entrenos.map(turn => {
            turn.abierto = false
            updateEntreno(turn)
            calcularEntreno(turn.id, access)

            return turn
        })

        return { ...state }
    }

    if (action.type === '@trains/gettingFreeUsers') {
        return action.payload
    }

    // Editar el estado del entreno abierto/cerrado
    if (action.type === '@trains/abierto') {
        const day = action.payload.day

        state[day].abiertos = !state[day].abiertos
        state[day].entrenos.map(turn => {
            turn.abierto = !turn.abierto
            updateEntreno(turn)

            return turn
        })

        return { ...state }
    }

    if (action.type === '@trains/validado') {
        // const {id} = action.payload
        // console.log('ID ENTRENO',state)
        const day = action.payload.day

        state[day].validados = true
        state[day].entrenos.map(turn => {
            turn.validado = true
            turn.abierto = false
            updateEntreno(turn)

            return turn
        })

        return { ...state }
    }

    if (action.type === '@trains/invalidar') {
        // const {id} = action.payload
        // console.log('ID ENTRENO',state)
        const day = action.payload.day

        state[day].validados = false
        state[day].entrenos.map(turn => {
            turn.validado = false
            turn.abierto = false
            updateEntreno(turn)

            return turn
        })

        return { ...state }
    }

    return state
}

export const trainState = (day) => {
    return {
        type: '@trains/abierto',
        payload: {
            day
        }
    }
}

export const trainValidation = (day) => {
    return {
        type: '@trains/validado',
        payload: {
            day
        }
    }
}

export const trainUnvalidation = (day) => {
    return {
        type: '@trains/invalidar',
        payload: {
            day
        }
    }
}

export const calculoEntrenos = (trains, day) => {
    const requests = trains[day].entrenos.map(async (turn) => {
        turn.abierto = false
        await updateEntreno(turn)
        await calcularEntreno(turn.id)

        return turn
    })

    return Promise.all(requests)
}

export const freeUsers = (id, id_bicicleta) => {
    return getFreeUsers(id, id_bicicleta)
}

export const initTrains = (calendarPage) => {
    return async (dispatch) => {
        let trains = undefined
        try {
            trains = await getAll(calendarPage)
        } catch (error) {}

        dispatch({
            type: '@trains/init',
            payload: trains,
        })
    }
}

export const isEmpty = async (calendarPage) => {
    let isEmpty = true
    const trains = await getAll(calendarPage)

    const keys = Object.keys(trains)
    for (let index = 0; index < keys.length; index++) {
        if (trains[keys[index]].validados) {
            isEmpty = false
            return isEmpty
        }
    }
    return isEmpty
}

//Enviamos funcion a coupleadmincard de pilotos
export const freePiloto = (id, id_bicicleta) => {
    return getFreeUsersPiloto(id, id_bicicleta)
}

//Enviamos funcion a coupleadmincard de copilotos
export const freeCopiloto = (id, id_bicicleta) => {
    return getFreeUsersCopiloto(id, id_bicicleta)
}