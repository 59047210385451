import React from 'react';
import './trainModal.scss'

import moment from 'moment';
import 'moment/locale/es';

import {
    Modal,
    DataTable,
    TableContainer,
    Table,
    TableHead,
    TableHeader,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
} from 'carbon-components-react';

const TrainModal = ({ open, turn, onClose }) => {
    // turn.fecha -> 2021-11-26T18:30:00+01:00
    const fecha = moment(turn.fecha).format('DD/MM/YYYY');
    const hora = moment(turn.fecha).format('LT');

    const [displayDrivers, setDisplayDrivers] = React.useState(true);
    const [displayCodrivers, setDisplayCodrivers] = React.useState(true);

    function getTableRows(turn, drivers) {
        let rows = [];
        let users = [];

        if (drivers === true) {
            users = turn.anotados.pilotos;
        } else {
            users = turn.anotados.copilotos;
        }

        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            const driver = {id: String(user.id), nombre: user.nombre};
            rows.push(driver);
        }

        rows.sort((a, b) => { return a.nombre.localeCompare(b.nombre) });
        return rows;
    }

    const headerData = [
        {
          header: 'NOMBRE',
          key: 'nombre',
        },
      ];

    return (
        <Modal
            open = {open}
            modalLabel = "Información del entreno"
            modalHeading={turn.nombre + " - " + fecha + " - Hora: " + hora}
            primaryButtonText="Close"
            passiveModal={true}
            onRequestClose={onClose}
            className="train-modal">
            
                <div className="train-modal__checkboxes">
                    {displayDrivers ?
                        <Checkbox id={"drivers-checkbox" + String(turn.id)} defaultChecked labelText="Mostrar pilotos" onChange={() => setDisplayDrivers(!displayDrivers)}/>
                        : 
                        <Checkbox id={"drivers-checkbox" + String(turn.id)} labelText="Mostrar pilotos" onChange={() => setDisplayDrivers(!displayDrivers)}/>
                    }
                    {displayCodrivers ?
                        <Checkbox id={"codrivers-checkbox" + String(turn.id)} defaultChecked labelText="Mostrar copilotos" onChange={() => setDisplayCodrivers(!displayCodrivers)}/>
                        :
                        <Checkbox id={"codrivers-checkbox" + String(turn.id)} labelText="Mostrar copilotos" onChange={() => setDisplayCodrivers(!displayCodrivers)}/>
                    }
                </div>

                {displayDrivers &&
                    <div className="table-container">
                        <h4>Pilotos anotados: {turn.anotados.pilotos.length}</h4>
                        <DataTable rows={getTableRows(turn, true)} headers={headerData} >
                            {({ rows, headers, getHeaderProps, getTableProps }) => (
                                <TableContainer title="">
                                    <Table {...getTableProps()}>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map(header => (
                                                    <TableHeader {...getHeaderProps({ header })}>
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map(row => (
                                                <TableRow key={"r"+row.id}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <TableCell key={cell.id}>
                                                                {cell.value}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </DataTable>
                    </div>
                }

                {displayCodrivers &&
                    <div className="table-container">
                        <h4>Copilotos anotados: {turn.anotados.copilotos.length}</h4>
                        <DataTable rows={getTableRows(turn, false)} headers={headerData} >
                            {({ rows, headers, getHeaderProps, getTableProps }) => (
                                <TableContainer title="">
                                    <Table {...getTableProps()}>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map(header => (
                                                    <TableHeader {...getHeaderProps({ header })}>
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map(row => (
                                                <TableRow key={"r"+row.id}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <TableCell key={cell.id}>
                                                                {cell.value}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </DataTable>
                    </div>
                }
        </Modal>
    );
}

export default TrainModal;