import React, { useState } from 'react';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
  HeaderGlobalBar,
  HeaderGlobalAction
} from 'carbon-components-react';
import image from '../../imgs/logo_discamino_sin_fondo.png';
import { Link } from 'react-router-dom';

import {
  Logout32
} from '@carbon/icons-react';
import { makeLogout } from '../../reducers/loginReducer';

const HeaderGlobal = () => {
  const [active, setActive] = useState(0);

  return (
    <div>
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <Header className="head" aria-label="Inicio" onClick={isSideNavExpanded === true ? onClickSideNavExpand : null}>

            <HeaderMenuButton
              aria-label="Abrir menu"
              onClick={(onClickSideNavExpand)}
              isActive={isSideNavExpanded}
            />
            <HeaderName onClick={() => setActive(0)} element={Link} to="/" prefix="">
              <img className="img" src={image} height={40} width={40} alt="" />
              <b className="back">DISCAMINO</b>

            </HeaderName>
            <HeaderNavigation aria-label="Discamino Header Navigation" className="bx--header" >
              <HeaderMenuItem className={active === 1 ? "active" : ""} onClick={() => setActive(1)} element={Link} to="/perfil">Mi Perfil</HeaderMenuItem>
              <HeaderMenuItem className={active === 2 ? "active" : ""} onClick={() => setActive(2)} element={Link} to="/calendario">Calendario</HeaderMenuItem>
              <HeaderMenuItem className={active === 3 ? "active" : ""} onClick={() => setActive(3)} element={Link} to="/pilotos">Pilotos</HeaderMenuItem>
              <HeaderMenuItem className={active === 4 ? "active" : ""} onClick={() => setActive(4)} element={Link} to="/copilotos">Copilotos</HeaderMenuItem>
              <HeaderMenuItem className={active === 5 ? "active" : ""} onClick={() => setActive(5)} element={Link} to="/ayuda">Ayuda</HeaderMenuItem>
            </HeaderNavigation>

            <SideNav
              aria-label="Side navigation"
              expanded={isSideNavExpanded}
              isPersistent={false}
            >
              <SideNavItems>
                <HeaderSideNavItems>
                  <HeaderMenuItem className={active === 1 ? "active" : ""} onClick={() => setActive(1)} element={Link} to="/perfil">Mi Perfil</HeaderMenuItem>
                  <HeaderMenuItem className={active === 2 ? "active" : ""} onClick={() => setActive(2)} element={Link} to="/calendario">Calendario</HeaderMenuItem>
                  <HeaderMenuItem className={active === 3 ? "active" : ""} onClick={() => setActive(3)} element={Link} to="/pilotos">Pilotos</HeaderMenuItem>
                  <HeaderMenuItem className={active === 4 ? "active" : ""} onClick={() => setActive(4)} element={Link} to="/copilotos">Copilotos</HeaderMenuItem>
                  <HeaderMenuItem className={active === 5 ? "active" : ""} onClick={() => setActive(5)} element={Link} to="/ayuda">Ayuda</HeaderMenuItem>
                </HeaderSideNavItems>
              </SideNavItems>
            </SideNav>
            <HeaderGlobalBar>
              <HeaderGlobalAction aria-label="Cerrar Sesión" onClick={() => { makeLogout() }}>
                <Logout32 />
              </HeaderGlobalAction>
            </HeaderGlobalBar>
          </Header>
        )}
      />
    </div>
  )
};
export default HeaderGlobal;
