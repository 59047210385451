import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initBikes } from '../../../reducers/bikesReducer';
import {
    Select,
    SelectItem,
} from 'carbon-components-react';
import TrainAdmin from '../Train/TrainAdmin';
import Train from '../Train/Train';


// Selector con el que haremos filtro de las biciletas
const BikesSelector = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(initBikes())
    }, [dispatch])

    const bikes = useSelector(state => state.bikes); //devuelve todas las bicicletas

    const [selection, changeSelection] = useState(0);

    const handleChange = (event) => {
        changeSelection(event.target.value);
    }

    return (
        <div>
        <div className="bx--grid">
            <Select
                id="select-bikes"
                defaultValue="placeholder-item"
                labelText=""
                light={true}
                onChange={handleChange}>
                <SelectItem value={0} text="Todas las bicicletas" />
                {bikes.map(bike =>
                    <SelectItem key={bike.id} value={bike.id} text={bike.nombre} />)
                }
            </Select>
            {/* Saber si es admin o no para mostrarle un entreno u otro */}
            {props.admin ? <TrainAdmin calendarPage={props.calendarPage} filter={selection}/> : <Train filter={selection} calendarPage={props.calendarPage}/>}
        </div>
        </div>

    );
};
export default BikesSelector;