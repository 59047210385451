import {getAll, updateBicicleta} from '../services/bikes';

export const bikesReducer = (state = [], action) => {
    if(action.type === '@bikes/init'){
        return action.payload
    }

    if(action.type === '@bikes/update'){
        const bike = action.payload.bike
        updateBicicleta(bike)
    }

    return state
}

export const updateBike = (bike) => { 
    return {
        type: '@bikes/update',
        payload: {
            bike
        }
    }
}

export const initBikes = () => {
    return async (dispatch) => {
        const bikes = await getAll()
        
        dispatch ({
            type: '@bikes/init',
            payload: bikes,
        })
    }
}