import React, {useState} from 'react';
import './ChangePswd.scss';
import {
  Form,
  TextInput,
  Button,
  Tile
} from 'carbon-components-react'
import { pswdChange } from '../../reducers/usersReducer';

function ChangePswd () {
  const [new_password1, setNew_password1] = useState('')
  const [new_password2, setNew_password2] = useState('')

  const [error, setError] = useState(false)
  const [errorCambiame, setErrorCambiame] = useState(false)
  const [errorMatch, setErrorMatch] = useState(false)

  let Password1Props = {
    className: 'some-class',
    id: 'new_password1',
    labelText: 'Nueva contraseña',
    placeholder: 'Nueva contraseña',
    showPasswordLabel: "Mostrar contraseña",
    hidePasswordLabel: "Ocultar contraseña"
  };

  const Password2Props = {
    className: 'some-class',
    id: 'new_password2',
    labelText: 'Confirmar contraseña',
    placeholder:"Confirmar contraseña",
    showPasswordLabel: "Mostrar contraseña",
    hidePasswordLabel: "Ocultar contraseña"
  };

  const InvalidPasswordProps = {
    className: 'some-class',
    id: 'invalidPswd',
    invalid: true,
    invalidText:
      'La contraseña no puede ser cambiame',
  };

  const MissMatchPasswords = {
    className: 'some-class',
    id: 'invalidPswd',
    invalid: true,
    invalidText:
      'Las contraseñas deben coincidir',
  };

  const onChangePswd = async (event) => {
    event.preventDefault()
    if(new_password1 === "cambiame"){
      setErrorMatch(false)
      setErrorCambiame(true)
      return 
      } 
      
    if(new_password1 === "" || new_password2 === ""){
        setErrorCambiame(false)
        setErrorMatch(false)
        setError(true)
        return
      }
    if (new_password1 !== new_password2){
          setErrorCambiame(false)
          setErrorMatch(true)
          return
      } 
      
      pswdChange(new_password1, new_password2).then(() => {
        window.location.replace("/")
      }).catch((err) => {
        setError(true)
      })
  }

  return (
    <div style={ {display: 'flex', alignItems: 'center', justifyContent: 'center', height:'100vh', } }>
      <Tile style={{padding: '1rem', width: '500px', height:'255px'}}>
      <Form>
        <div style={{margin: '1rem'}}>  
          {errorCambiame ? 
            <TextInput.PasswordInput value = {new_password1} {...Password1Props} {...InvalidPasswordProps} onChange = {(event) => setNew_password1(event.target.value)}/>
            :
            <TextInput.PasswordInput value = {new_password1} {...Password1Props} onChange = {(event) => setNew_password1(event.target.value)}/>
          }
        </div>

        <div style={{margin: '1rem'}}>
          {(errorMatch || error) ? 
            <TextInput.PasswordInput value = {new_password2} {...Password2Props} {...MissMatchPasswords} onChange = {(event) => setNew_password2(event.target.value)}/>
            :
            <TextInput.PasswordInput value = {new_password2} {...Password2Props} onChange = {(event) => setNew_password2(event.target.value)}/>
          }
        </div>
        <Button tooltipAlignment='center' type="submit" className="calendar-button" onClick = {(event) => onChangePswd(event)}>
          CAMBIAR
        </Button>
      </Form>
      </Tile>
    </div>
  );
};    

export default ChangePswd;