import React from 'react';
import UserCard from '../../components/Usuarios/UserCard/UserCard';
import '../../pages/CopilotosPage/_copilotos-page.scss';

const CopilotosPage = () => {
  return <div>
    {/* El scss del título está ubicado en _copilotos-page.scss ya que, al tener el mismo nombre, no es necesario replicar el mismo código en 2 archivos */}
    <h1 className="users-title">COPILOTOS</h1>
    <div className="bx--grid">
      <UserCard bool={false} />
    </div>
  </div>
};
export default CopilotosPage;